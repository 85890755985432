import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { "grid-list-md": "" } },
    [
      _c(
        VLayout,
        { attrs: { row: "", wrap: "" } },
        [
          _c(VFlex, { attrs: { xs6: "" } }, [
            _c("span", { staticClass: "H3-Primary-Left" }, [
              _vm._v("AP3 Notification Management")
            ])
          ]),
          _c(VFlex, { attrs: { xs6: "" } }, [
            _c(
              "div",
              { staticClass: "right" },
              [
                _c(
                  VBtn,
                  {
                    staticClass: "newNotifBtn",
                    attrs: { color: "primary" },
                    on: { click: _vm.newNotification }
                  },
                  [
                    _c(VIcon, { attrs: { left: "", dark: "" } }, [
                      _vm._v("mdi-plus")
                    ]),
                    _vm._v("\n          New Notification\n        ")
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            VFlex,
            { attrs: { xs12: "" } },
            [
              _c(VDataTable, {
                staticClass: "data-table",
                attrs: {
                  headers: _vm.headers,
                  items: this.notifications,
                  loading: _vm.loading,
                  "hide-actions": ""
                },
                on: {
                  "update:items": function($event) {
                    return _vm.$set(this, "notifications", $event)
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "items",
                    fn: function(props) {
                      return [
                        _c("tr", [
                          _c("td", [
                            _c("div", [_vm._v(_vm._s(props.item.title || " "))])
                          ]),
                          _c("td", [
                            _c("div", { staticClass: "text-cell" }, [
                              _vm._v(_vm._s(props.item.text || " "))
                            ])
                          ]),
                          _c("td", [
                            _c("div", [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.getNotificationStatus(props.item)
                                  ) +
                                  "\n              "
                              )
                            ])
                          ]),
                          _c("td", [
                            _c(
                              "span",
                              { staticClass: "schedule-span" },
                              [
                                _c(VIcon, [_vm._v("mdi-calendar-blank")]),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.getSheduleDateString(
                                        props.item.release_date ||
                                          props.item.date_modified
                                      )
                                    )
                                )
                              ],
                              1
                            )
                          ]),
                          _c("td", [
                            _c(
                              "div",
                              { staticClass: "flex-content" },
                              [
                                _c(
                                  VTooltip,
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                VBtn,
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      flat: "",
                                                      icon: "",
                                                      color: "grey"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.editNotif(
                                                          props.item.id
                                                        )
                                                      }
                                                    }
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c(VIcon, [
                                                    _vm._v("mdi-pencil")
                                                  ])
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [_c("span", [_vm._v("Edit Notification")])]
                                ),
                                _c(
                                  VTooltip,
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                VBtn,
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      flat: "",
                                                      icon: "",
                                                      color: "grey"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.confirm(
                                                          "delete",
                                                          props.item.id,
                                                          props.index
                                                        )
                                                      }
                                                    }
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c(VIcon, [
                                                    _vm._v("mdi-delete")
                                                  ])
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [_c("span", [_vm._v("Delete Notification")])]
                                )
                              ],
                              1
                            )
                          ])
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }