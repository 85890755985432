<template>
  <v-container grid-list-md>
    <v-layout row wrap>
      <v-flex xs6>
        <span class="H3-Primary-Left">AP3 Notification Management</span>
      </v-flex>
      <v-flex xs6>
        <div class="right">
          <v-btn color="primary" class="newNotifBtn" @click="newNotification">
            <v-icon left dark>mdi-plus</v-icon>
            New Notification
          </v-btn>
        </div>
      </v-flex>
      <v-flex xs12>
        <v-data-table
          :headers="headers"
          :items.sync="this.notifications"
          :loading="loading"
          class="data-table"
          hide-actions
        >
          <template v-slot:items="props">
            <tr>
              <td>
                <div>{{ props.item.title || ' ' }}</div>
              </td>
              <td>
                <div class="text-cell">{{ props.item.text || ' ' }}</div>
              </td>
              <td>
                <div>
                  {{ getNotificationStatus(props.item) }}
                </div>
              </td>

              <td>
                <span class="schedule-span"
                  ><v-icon>mdi-calendar-blank</v-icon>
                  {{
                    getSheduleDateString(props.item.release_date || props.item.date_modified)
                  }}</span
                >
              </td>
              <td>
                <div class="flex-content">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" flat icon color="grey" @click="editNotif(props.item.id)">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit Notification</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        flat
                        icon
                        color="grey"
                        @click="confirm('delete', props.item.id, props.index)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete Notification</span>
                  </v-tooltip>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import DateTime from 'luxon/src/datetime.js';
import cloneDeep from 'lodash/cloneDeep';
import { mapActions, mapState } from 'vuex';

export default {
  data: () => ({
    loading: false,
    headers: [
      { text: 'Notification Name', value: 'title', sortable: false, align: 'left' },
      { text: 'Notification Description', value: 'text', sortable: false, align: 'left' },
      { text: 'Notification Status', value: 'status', sortable: false, align: 'left' },
      { text: 'Date Scheduled', value: 'date_modified', sortable: false, align: 'left' },
      { text: 'Action', sortable: false, align: 'left' },
    ],
  }),
  computed: {
    ...mapState('notifications', ['notifications']),
  },
  methods: {
    ...mapActions('notifications', ['fetchNotifications']),
    async confirm(action, notif_id, index) {
      const confirmed = await this.$confirm({
        title: `${this.capitalize(action)} Notification?`,
        message: `Are you sure you want to ${action} this notification?`,
        buttonTrueText: `${action.toUpperCase()}`,
        buttonFalseText: 'CANCEL',
      });
      if (confirmed) {
        this.deleteNotif(notif_id, index);
      }
    },
    capitalize(value) {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    checkStatusDate(release_date) {
      const today_parsed = Date.parse(new Date());
      const parsed_release_date = Date.parse(release_date);
      return today_parsed >= parsed_release_date ? 'Released' : 'Pending';
    },
    checkStatus(status) {
      return status ? 'Released' : 'Pending';
    },
    newNotification() {
      this.$router.push({
        name: 'notification-new',
      });
    },
    editNotif(id) {
      this.$router.push({
        name: 'notification-edit',
        params: {
          notification_id: id,
        },
      });
    },
    async deleteNotif(notif_id, index) {
      try {
        await this.api.delete(`notification/${notif_id}`);
        const notifs_copy = cloneDeep(this.$store.state.notifications.notifications);
        notifs_copy.splice(index, 1);
        this.$store.commit('notifications/setNotifications', notifs_copy);
      } catch (err) {
        this.$toast.error(`Unable to save notification.`);
      }
    },
    getNotificationStatus(item) {
      return item.status
        ? this.checkStatus(item.status)
        : this.checkStatusDate(item.release_date || item.date_modified);
    },
    getSheduleDateString(date) {
      if (date) {
        return `${DateTime.fromISO(date).toFormat('DD')}`;
      }
      return ' ';
    },
  },
  async mounted() {
    this.loading = true;
    this.$store.commit('adminPanel/setLoading', true);
    this.$store.commit('adminPanel/setViewTitle', {
      depth: 0,
      title: 'AP3 Notification Management',
      to: { name: 'notification-manager' },
    });
    const now = new Date();
    const end = new Date(now.setDate(now.getDate() + 365)).toISOString();
    const location = 'notifcation_manager';
    try {
      await this.fetchNotifications({ end, location });
      this.loading = false;
    } catch (error) {
      console.error(error);
      this.$toast.error('Could not fetch notifcations');
    } finally {
      this.loading = false;
      this.$store.commit('adminPanel/setLoading', false);
    }
  },
};
</script>
<style scoped>
.data-table >>> .v-datatable thead {
  background: #fafafa;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
}
.data-table >>> .v-datatable thead th {
  color: black;
}
.data-table >>> .v-datatable thead tr:not(.v-datatable__progress) th:first-child,
.data-table >>> .v-datatable tbody tr td:first-child {
  padding: 24px 12px 24px 24px !important;
}
.data-table >>> .v-datatable thead tr:not(.v-datatable__progress) th:last-child,
.data-table >>> .v-datatable tbody tr td:last-child {
  padding: 24px 24px 24px 12px !important;
}
.data-table >>> .v-datatable tbody tr td .text-cell {
  max-width: 320px;
  max-height: 200px;
  overflow: scroll;
}
.data-table {
  box-shadow: 3px 3px 3px 3px #d8d8d8;
}
.schedule-span {
  display: block;
  margin-bottom: 15px;
  min-width: 150px;
}
.schedule-span:last-of-type {
  margin-bottom: 0;
}
.tableHeader {
  color: black;
}
.newNotifBtn {
  height: 48px;
  border-radius: 4px;
}
.filterIcon {
  width: 24px;
  height: 24px;
  color: black;
  margin-right: 20px;
}
.filterBlock {
  padding: 20px 6px 8px 6px;
  width: fit-content;
  margin-left: auto;
}
.flex-content {
  display: flex;
}
</style>
